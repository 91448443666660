@charset "utf-8";

.p-jigyosho-contact-inquiry__box {
  width: 100%;
  padding: 0 24px 16px;
}

.p-jigyosho-contact-inquiry__tel {
  margin-top: 12px;
  box-shadow: 0 3px $honne-blue;
}

.p-jigyosho-contact-inquiry__brochure__link {
  box-shadow: 0 5px #ca3e3e;
}

.p-jigyosho-contact-inquiry__brochure__text {
  margin-bottom: 4px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-red-bright;
  text-align: center;
  letter-spacing: $ls-x-small;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-contact-inquiry__brochure {
    margin-bottom: 12px;
  }

  .p-jigyosho-contact-inquiry__brochure__title {
    font-size: $fs-xl;
  }

  .p-jigyosho-contact-inquiry__tel {
    padding: 10px 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-contact-inquiry {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    a:only-child {
      height: auto;
      margin: 0 auto;
    }
  }

  .p-jigyosho-contact-inquiry__brochure {
    width: calc(50% - 8px);
    margin-bottom: 0;
  }

  .p-jigyosho-contact-inquiry__brochure__link {
    height: 91px;
  }

  .p-jigyosho-contact-inquiry__tel {
    width: calc(50% - 8px);
    height: 91px;
    margin-top: 0;
    box-shadow: 0 5px rgba(0, 0, 0, 0.2);
  }

  .p-jigyosho-contact-inquiry__brochure__text {
    letter-spacing: $ls-small;
  }
}
